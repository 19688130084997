import React from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks';

import { ConsultingCompanyAutocompleteConsultingCompanySearchQuery } from 'src/components/common/autocomplete/ConsultingCompanyAutocomplete';

const useCompaniesRefetch = () => {
    const environment = useRelayEnvironment();
    const [companies, setCompanies] = React.useState([]);
    const [isRefreshing, setIsRefreshing] = React.useState(false);

    const refetch = React.useCallback(
        (value) => {
            if (value.length < 3) {
                return;
            }
            setIsRefreshing(true);
            fetchQuery(environment, ConsultingCompanyAutocompleteConsultingCompanySearchQuery, {
                text: value,
            }).subscribe({
                complete: () => {
                    setIsRefreshing(false);
                },
                error: () => {
                    setIsRefreshing(false);
                    setCompanies([]);
                },
                next: (data) => {
                    setCompanies(data.consultingCompaniesSearch.edges.map((item) => item.node));
                },
            });
        },
        [isRefreshing, environment],
    );

    return {
        refetch,
        companies,
        isRefreshing,
    };
};

export default useCompaniesRefetch;
